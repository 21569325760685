import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

function Home() {
  return (
    <div className="home-container">
    <header className="hero">
        <div className="hero-content">
        <h1>Welcome to ZipCode AutoFill</h1>
        <p className="tagline">Streamline Your Address Management with One Simple ZIP Code</p>
        <Link to="/register" className="cta-button">Get Started</Link>
        </div>
    </header>


      <section className="features">
        <h2>Why Choose ZipCode AutoFill?</h2>
        <div className="feature-grid">
          <div className="feature">
            <i className="fas fa-magic"></i>
            <h3>Automated Address Completion</h3>
            <p>Instantly populate city, state, county, and country fields from just a ZIP code.</p>
          </div>
          <div className="feature">
            <i className="fas fa-plug"></i>
            <h3>Seamless HubSpot Integration</h3>
            <p>Works flawlessly with your HubSpot CRM for both contacts and companies.</p>
          </div>
          <div className="feature">
            <i className="fas fa-cogs"></i>
            <h3>Customizable Fields</h3>
            <p>Choose which fields to update based on your specific HubSpot setup.</p>
          </div>
          <div className="feature">
            <i className="fas fa-flag-usa"></i>
            <h3>US ZIP Codes Only</h3>
            <p>Specialized for 5-digit US ZIP codes, ensuring accurate results for United States addresses.</p>
          </div>
        </div>
      </section>

      <section className="features">
        <h2>How It Works</h2>
        <div className="feature-grid">
            <div className="feature">
            <i className="fa-solid fa-1"></i>
            <h3>Connect Your HubSpot Account</h3>
            <p>Seamlessly link your HubSpot account to enable automatic address updates.</p>
            </div>
            <div className="feature">
            <i className="fa-solid fa-2"></i>
            <h3>Configure Fields to Update</h3>
            <p>Choose which fields in HubSpot should be auto-filled based on your preferences.</p>
            </div>
            <div className="feature">
            <i className="fa-solid fa-3"></i>
            <h3>Enter a ZIP Code</h3>
            <p>Simply input a ZIP code within your HubSpot account to trigger auto-fill functionality.</p>
            </div>
            <div className="feature">
            <i className="fa-solid fa-4"></i>
            <h3>Auto-Populate Address Fields</h3>
            <p>Watch as city, state, and other fields are filled in automatically with precision!</p>
            </div>
        </div>
    </section>


      <section className="pricing-preview">
        <h2>Simple, Transparent Pricing</h2>
        <p>Pay only for what you use. Purchase credits and use them as needed.</p>
        <Link to="/pricing" className="secondary-button">View Pricing Plans</Link>
      </section>

      <section className="cta">
        <h2>Ready to Streamline Your Address Management?</h2>
        <p style={{ color: 'black' }}>Join the businesses saving time and improving data accuracy with ZipCode AutoFill.</p>
        <Link to="/register" className="cta-button">Sign Up Now</Link>
      </section>

    <br />

      <footer className="disclaimer">
        <p>ZipCode AutoFill uses Google's Geocoding API to provide address information for 5-digit US ZIP codes and, to a limited degree, Canadian postal codes. While we strive for accuracy, please verify all auto-filled information for critical communications.</p>
        <p>By using our service, you agree to comply with all applicable data protection laws and HubSpot's terms of service.</p>
      </footer>
    </div>
  );
}

export default Home;
